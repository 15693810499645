@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h3{
    @apply text-2xl md:text-3xl xl:text-4xl font-bold;
  }
  h4{
    @apply text-2xl font-bold border-b-2;
  }
  ul{
    @apply list-disc pl-5 space-y-2 py-2;
  }
  li > a{
    @apply hover:text-orangeA underline text-placeholderGray font-medium; 
  }
  input, textarea {
    @apply text-black font-semibold; 
  }
}

@layer components {

  /** Inputs */
  .standardInput{
    @apply p-1 outline-none ring-0 border-2 bg-placeholderGray bg-opacity-0;
  }
  .inputRequired{
    @apply standardInput bg-wrongRedB bg-opacity-40 border-wrongRedB;
  }
  .orangeFocus{
    @apply hover:border-orangeA focus:border-orangeA;
  }

  /** Labels */
  .contactLabel{
    @apply font-semibold;
  }
  .labelRequired{
    @apply contactLabel;
  }
  .labelMissing{
    @apply contactLabel text-wrongRedA;
  }


  /** Buttons */
  .buttonContainer{
    @apply p-10 flex place-content-center;
  }
  .standardButton{
    @apply cursor-pointer p-3 border-2 text-lg font-semibold;
  }
  .pageButton{
    @apply standardButton hover:bg-opacity-20 border-0 py-1 px-2 text-center h-9 hover:bg-placeholderGray;
  }
  .pageButtonSelected{
    @apply pageButton bg-orangeA bg-opacity-100 hover:bg-orangeA;
  }
  .pageButtonDisabled{
    @apply pageButton text-placeholderGray opacity-50 cursor-not-allowed hover:bg-opacity-0;
  }
  .pageButtonStep{
    @apply text-4xl text-center py-0 px-4;
  }

  
  /** Post Previews */
  .post{
    @apply md:w-2/3;
  }
  .loading{
    @apply m-1 animate-pulse;
  }

  .previewContainer{
    @apply overflow-hidden 
    h-40 
    sm:h-48 
    md:h-52 
    lg:h-56 lg:w-2/3
    xxl:w-1/2
    xxl:h-80;
  }
  .preview{
    @apply p-5 gap-x-2 gap-y-2 flex flex-nowrap justify-start bg-white w-full;
  }
  .previewImage{
    @apply h-24 w-24 
    sm:h-28 sm:w-28
    md:h-32 md:w-32
    lg:h-40 lg:w-40
    xl:h-48 xl:w-48 
    xxl:h-52 xxl:w-52
    xxxl:h-64 xxxl:w-64
    p-0 object-cover overflow-hidden mx-0 justify-self-start flex-shrink-0;
  }
  .previewTitle{
    @apply border-0
    text-base
    sm:text-lg
    md:text-xl 
    xl:text-2xl;
  }
  .projectType{
    @apply bg-opacity-30 border-t-2 font-semibold h-8 px-1;
  }
  .swipeArrow{
    @apply text-7xl px-2 animate-swipe my-auto text-placeholderGray;
  }
  .info{
    @apply w-full h-full bg-white z-10 bg-opacity-70 backdrop-blur-md cursor-pointer p-2
    lg:p-5 
    xl:p-8
    flex flex-col justify-between;
  }


  /** misc */
  .spacer{
    @apply h-96;
  }

  /** header stuff */
  .headerNav{
    @apply flex flex-col md:flex-row list-none md:w-2/3 lg:w-1/2 ml-0 font-bold text-xl justify-end lg:px-5 space-y-0 pl-0 py-0;
  }
  .headerNavLink{
    @apply pt-3 w-28 flex place-content-center hover:bg-orangeA hover:bg-opacity-60 text-black hover:text-white;
  }
  .headerNav > .active{
    @apply bg-orangeA bg-opacity-90;
  }
  .mobileHeaderLink{
    @apply w-full place-content-start p-2;
  }


  /** home page stuff */
  .slide{
    @apply w-screen h-full shrink-0 ;
  }
  .slideHeader{
    @apply mb-5 md:mb-10 mx-16 border-b-2;
  }
  .slideContentContainer{
    @apply flex flex-col md:flex-row justify-around lg:mx-5;
  }
  .slideComponent{
    @apply p-5 m-5 md:m-10 bg-orangeA border-2 border-black border-l-8 hover:scale-105 duration-75 rounded-lg;
  }
  .slideComponent > p{
    @apply text-base font-medium lg:text-lg
  }
  .slideComponent li{
    @apply text-base font-medium lg:text-lg;
  }
  .slideComponentHeader{
    @apply border-0 text-lg md:text-xl;
  }

  .aboutContentHeader{
    @apply text-lg lg:text-2xl font-bold border-b-2;
  }
  .skillsContainer{
    @apply px-5 lg:w-3/4 mx-auto flex flex-col gap-y-10 pb-20 md:pt-10;
  }

  .overview, .soft, .hard, .education{
    @apply
    text-base
    md:text-lg md:py-1
    xl:text-xl xl:py-2
  }
  .soft ul, .hard ul, .overview ul .education ul{
    @apply ml-5;
  }


  /** post pages stuff */
  .postContainer{
    @apply px-4 py-10 space-y-2 
    sm:space-y-3
    md:space-y-4
    lg:space-y-6 
    xl:space-y-8;
  }

  .postContainerTitle{
    @apply font-extrabold pt-20 mb-10 border-b-2
    text-2xl pb-2
    lg:text-3xl lg:pb-3
    xl:text-4xl xl:pb-4
    xxl:text-5xl xxl:pb-5
    xxxl:text-6xl xxxl:pb-6;
  }

  .orangeHover{
    @apply hover:scale-110 duration-75 hover:text-orangeA;
  }


  /** blog post */
  .blogPost{
    @apply p-4;
  }

  .blogPost >*{
    @apply xl:w-2/3;
  }

  .blockContentBackground{
    @apply bg-white p-4;
  }

  /** headers */
  .blockContent h1{
    @apply text-2xl 
    md:text-3xl 
    lg:text-4xl 
    xl:text-6xl font-extrabold underline;
  }

  .blockContent h2{
    @apply text-4xl font-extrabold underline pt-16;
  }

  .blockContent h3{
    @apply text-lg
    lg:text-xl 
    xl:text-2xl font-bold underline pt-10;
  }

  .blockContent h4{
    @apply text-base
    lg:text-lg
    xl:text-xl font-bold border-0 pt-5;
  }

  /** list */
  .blockContent ul{
    @apply pl-5 list-disc space-y-2 py-2;
  }
  .blockContent li{
    @apply ml-5;
  }


  /** text */
  .blockContent p{
    @apply py-2;
  }

  .blockContent strong{
    @apply font-semibold;
  }

  .blockContent blockquote{
    @apply text-orangeA;
  }

  .blockContent a{
    @apply text-placeholderGray hover:text-orangeA underline;
  }




  /** author */
  .author p {
    @apply py-0;
  }

  #anchorLikeButton{
    @apply standardButton orangeHover text-black no-underline;
  }
}


/*! NEW STUFF */

.textTierSmall{
  @apply
  text-sm
  md:text-base
  lg:text-lg
}

.textTierBase{
  @apply
  text-base
  md:text-lg
  lg:text-xl
}

.textTierLarge{
  @apply
  text-lg
  md:text-xl
  lg:text-2xl
}

.textTierExtraLarge{
  @apply
  text-xl
  md:text-2xl
  lg:text-3xl
  xl:text-4xl
}

.textTierLargest{
  @apply
  text-2xl
  md:text-4xl
  lg:text-6xl
  xl:text-8xl
}

.paddingLevelOne{
  @apply
  p-2
  md:p-3
  lg:p-4
}

.paddingLevelTwo{
  @apply
  p-4
  md:p-6
  lg:p-10
}

.paddingLevelThree{
  @apply
  p-5
  md:p-10
  lg:p-16
}

.homeNav{
  @apply flex place-content-center space-x-5 m-0 p-0 space-y-0 border-b-2 border-orangeA bg-white bg-opacity-50 backdrop-blur-sm
}

.homeNavLink{
  @apply p-5 w-56 flex place-content-center hover:bg-orangeA hover:bg-opacity-60 text-black hover:text-white font-semibold textTierLarge
}

.homeNav > .active{
  @apply bg-orangeA bg-opacity-90
}

.serviceBox{
  @apply bg-orangeA bg-opacity-80 rounded-md p-10 md:p-20 m-10
}

.serviceBox > h3{
  @apply font-bold text-3xl
  lg:text-4xl
}

.modalContainer{
  @apply flex flex-col md:flex-row justify-around
}

.modalComponent{
  @apply flex-col place-content-start text-left bg-orangeA bg-opacity-75 backdrop-blur-sm p-5 rounded-md my-10 mx-2
}

.modalComponent h4{
  @apply font-extrabold
  text-2xl
  xl:text-3xl
}

.modalComponent p{
  @apply
  text-lg
  md:text-xl
  xl:text-2xl
}

.modalComponent ul{
  @apply
  text-lg
  md:text-xl
  xl:text-2xl
}